@import "variables";
/* You can add global styles to this file, and also import other style files */
$body-bg: $primary-dark-color;
$body-color: #ffffff;
$link-color: #12b3fd;
$primary: #3f88fd;
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.app-green-text {
  color: $app-green;
}
.app-green-bg {
  background-color: $app-green;
}
.app-red-text {
  color: $app-red;
}
.app-red-bg {
  background-color: $app-red;
}
.primary-dark-color-bg {
  background-color: $primary-dark-color;
}
.secondary-dark-color-bg {
  background-color: $secondary-dark-color;
}
.primary-dark-color-bg-light-1 {
  background-color: $primary-dark-color-light-1;
}

.dark-light-text {
  color: $light-grey;
}

.table.app-table {
  & th, td {
    border-top: 1px solid $thin-border-color;
    border-right: 1px solid $thin-border-color;
  }
  & th:first-child, td:first-child {
    border-left: 1px solid $thin-border-color;
  }
  & > thead {
    background-color: $primary-dark-color-light-1;
    color: $light-grey;
    & th {
      font-weight: 300;
      border-bottom: none;
    }
  }
  & > tbody {
    tr {
      font-weight: 300;
      &:last-child {
        border-bottom: 1px solid $thin-border-color;
      }
    }
  }
}

input, textarea{
  background-color: $secondary-dark-color !important;
  color: white !important;
  border: 1px solid $primary-dark-color-light-1 !important;
}

label.form-label {
  color: $light-grey;
}

// ng-bootstrap date picker customization

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.ngb-dp-header,
.ngb-dp-content.ngb-dp-months,
.ngb-dp-month-name,
.ngb-dp-week.ngb-dp-weekdays {
  background-color: $secondary-dark-color !important;
}

ngb-datepicker-navigation-select>.custom-select {
  background-color: $light-grey !important;
  color: white !important;
  border: none !important;
}
.custom-day.range, .custom-day:hover {
  background-color: $app-green !important;
}

.custom-day.range.faded {
  background-color: lighten($app-green, 20%) !important;
}
.ngb-dp-day > .range {
  // background-color: red !important;
}
.datepicker-outline,
.ngb-dp-day > .btn-light {
  color: white;
  border-color: $primary-dark-color-light-1;
}

.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.modal-content{
  background-color: $primary-dark-color-light-1;
}

.list-group-item{
  color: $light-grey;
  background-color: $body-bg;
  &:not(:last-child){
  border-bottom: 1px solid $secondary-dark-color;
}
  &:hover{
    background-color: $secondary-dark-color;
    color: white;
  }
}

.modal-footer{
  border-top: none;
}
.modal-header{
  border-bottom: none;
}

.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}

.btn-app {
  background-color: $secondary-dark-color;
  &:not(:first-child) {
    border-left: 1px solid $primary-dark-color;
  }
}

.popover.bs-popover-top {
  background-color: $primary-dark-color;
}
.popover-header{
  background-color: $secondary-dark-color;
}
